body .h5p-iframe .h5p-content {
  background: transparent;
}
.h5p-player-component-root .h5p-iframe .h5p-container {
  overflow-y: auto;
}
.h5p-container::-webkit-scrollbar {
  background-color: transparent;
  width: 14px;
  border: solid 3px transparent;
}
.h5p-container::-webkit-scrollbar-track {
  background-color: transparent;
  border: solid 3px transparent;
}
.h5p-container::-webkit-scrollbar-thumb {
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 20px;
  background-color: #606060;
}
.h5p-container::-webkit-scrollbar-button {
  display: none;
}
